"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_js_1 = require("../utils.js");
const messages_gen_js_1 = require("../app/messages.gen.js");
const guards_js_1 = require("../app/guards.js");
function resolveURL(url, location = document.location) {
    url = url.trim();
    if (url.startsWith('/')) {
        return location.origin + url;
    }
    else if (url.startsWith('http://') ||
        url.startsWith('https://') ||
        url.startsWith('data:') // any other possible value here?
    ) {
        return url;
    }
    else {
        return location.origin + location.pathname + url;
    }
}
const PLACEHOLDER_SRC = 'https://static.openreplay.com/tracker/placeholder.jpeg';
function default_1(app) {
    function sendPlaceholder(id, node) {
        app.send((0, messages_gen_js_1.SetNodeAttribute)(id, 'src', PLACEHOLDER_SRC));
        const { width, height } = node.getBoundingClientRect();
        if (!node.hasAttribute('width')) {
            app.send((0, messages_gen_js_1.SetNodeAttribute)(id, 'width', String(width)));
        }
        if (!node.hasAttribute('height')) {
            app.send((0, messages_gen_js_1.SetNodeAttribute)(id, 'height', String(height)));
        }
    }
    const sendSrcset = function (id, img) {
        const { srcset } = img;
        if (!srcset) {
            return;
        }
        const resolvedSrcset = srcset
            .split(',')
            .map((str) => resolveURL(str))
            .join(',');
        app.send((0, messages_gen_js_1.SetNodeAttribute)(id, 'srcset', resolvedSrcset));
    };
    const sendSrc = function (id, img) {
        const src = img.src;
        app.send((0, messages_gen_js_1.SetNodeAttributeURLBased)(id, 'src', src, app.getBaseHref()));
    };
    const sendImgAttrs = app.safe(function () {
        const id = app.nodes.getID(this);
        if (id === undefined) {
            return;
        }
        const { src, complete, naturalWidth, naturalHeight, srcset } = this;
        if (!complete) {
            return;
        }
        const resolvedSrc = resolveURL(src || ''); // Src type is null sometimes. - is it true?
        if (naturalWidth === 0 && naturalHeight === 0) {
            if ((0, utils_js_1.isURL)(resolvedSrc)) {
                app.send((0, messages_gen_js_1.ResourceTiming)((0, utils_js_1.timestamp)(), 0, 0, 0, 0, 0, resolvedSrc, 'img'));
            }
        }
        else if (resolvedSrc.length >= 1e5 || app.sanitizer.isMasked(id)) {
            sendPlaceholder(id, this);
        }
        else {
            sendSrc(id, this);
            sendSrcset(id, this);
        }
    });
    const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            if (mutation.type === 'attributes') {
                const target = mutation.target;
                const id = app.nodes.getID(target);
                if (id === undefined) {
                    return;
                }
                if (mutation.attributeName === 'src') {
                    sendSrc(id, target);
                }
                if (mutation.attributeName === 'srcset') {
                    sendSrcset(id, target);
                }
            }
        }
    });
    app.attachStopCallback(() => {
        observer.disconnect();
    });
    app.nodes.attachNodeCallback((node) => {
        if (!(0, guards_js_1.hasTag)(node, 'IMG')) {
            return;
        }
        app.nodes.attachElementListener('error', node, sendImgAttrs.bind(node));
        app.nodes.attachElementListener('load', node, sendImgAttrs.bind(node));
        sendImgAttrs.call(node);
        observer.observe(node, { attributes: true, attributeFilter: ['src', 'srcset'] });
    });
}
exports.default = default_1;
