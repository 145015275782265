"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const messages_gen_js_1 = require("../app/messages.gen.js");
const guards_js_1 = require("../app/guards.js");
function hasAdoptedSS(node) {
    return ((0, guards_js_1.isRootNode)(node) &&
        // @ts-ignore
        !!node.adoptedStyleSheets);
}
function default_1(app) {
    if (app === null) {
        return;
    }
    if (!hasAdoptedSS(document)) {
        app.attachStartCallback(() => {
            // MBTODO: pre-start sendQueue app
            app.send((0, messages_gen_js_1.TechnicalInfo)('no_adopted_stylesheets', ''));
        });
        return;
    }
    let nextID = 0xf;
    const styleSheetIDMap = new Map();
    const adoptedStyleSheetsOwnings = new Map();
    const updateAdoptedStyleSheets = (root) => {
        let nodeID = app.nodes.getID(root);
        if (root === document) {
            nodeID = 0; // main document doesn't have nodeID. ID count starts from the documentElement
        }
        if (!nodeID) {
            return;
        }
        let pastOwning = adoptedStyleSheetsOwnings.get(nodeID);
        if (!pastOwning) {
            pastOwning = [];
        }
        const nowOwning = [];
        const styleSheets = root.adoptedStyleSheets;
        for (const s of styleSheets) {
            let sheetID = styleSheetIDMap.get(s);
            const init = !sheetID;
            if (!sheetID) {
                sheetID = ++nextID;
            }
            nowOwning.push(sheetID);
            if (!pastOwning.includes(sheetID)) {
                app.send((0, messages_gen_js_1.AdoptedSSAddOwner)(sheetID, nodeID));
            }
            if (init) {
                const rules = s.cssRules;
                for (let i = 0; i < rules.length; i++) {
                    app.send((0, messages_gen_js_1.AdoptedSSInsertRuleURLBased)(sheetID, rules[i].cssText, i, app.getBaseHref()));
                }
            }
        }
        for (const sheetID of pastOwning) {
            if (!nowOwning.includes(sheetID)) {
                app.send((0, messages_gen_js_1.AdoptedSSRemoveOwner)(sheetID, nodeID));
            }
        }
        adoptedStyleSheetsOwnings.set(nodeID, nowOwning);
    };
    function patchAdoptedStyleSheets(prototype) {
        const nativeAdoptedStyleSheetsDescriptor = Object.getOwnPropertyDescriptor(prototype, 'adoptedStyleSheets');
        if (nativeAdoptedStyleSheetsDescriptor) {
            Object.defineProperty(prototype, 'adoptedStyleSheets', Object.assign(Object.assign({}, nativeAdoptedStyleSheetsDescriptor), { set: function (value) {
                    // @ts-ignore
                    const retVal = nativeAdoptedStyleSheetsDescriptor.set.call(this, value);
                    updateAdoptedStyleSheets(this);
                    return retVal;
                } }));
        }
    }
    const patchContext = (context) => {
        patchAdoptedStyleSheets(context.Document.prototype);
        patchAdoptedStyleSheets(context.ShadowRoot.prototype);
        //@ts-ignore TODO: configure ts (use necessary lib)
        const { insertRule, deleteRule, replace, replaceSync } = context.CSSStyleSheet.prototype;
        //@ts-ignore
        context.CSSStyleSheet.prototype.replace = function (text) {
            return replace.call(this, text).then((sheet) => {
                const sheetID = styleSheetIDMap.get(this);
                if (sheetID) {
                    app.send((0, messages_gen_js_1.AdoptedSSReplaceURLBased)(sheetID, text, app.getBaseHref()));
                }
                return sheet;
            });
        };
        //@ts-ignore
        context.CSSStyleSheet.prototype.replaceSync = function (text) {
            const sheetID = styleSheetIDMap.get(this);
            if (sheetID) {
                app.send((0, messages_gen_js_1.AdoptedSSReplaceURLBased)(sheetID, text, app.getBaseHref()));
            }
            return replaceSync.call(this, text);
        };
        context.CSSStyleSheet.prototype.insertRule = function (rule, index = 0) {
            const sheetID = styleSheetIDMap.get(this);
            if (sheetID) {
                app.send((0, messages_gen_js_1.AdoptedSSInsertRuleURLBased)(sheetID, rule, index, app.getBaseHref()));
            }
            return insertRule.call(this, rule, index);
        };
        context.CSSStyleSheet.prototype.deleteRule = function (index) {
            const sheetID = styleSheetIDMap.get(this);
            if (sheetID) {
                app.send((0, messages_gen_js_1.AdoptedSSDeleteRule)(sheetID, index));
            }
            return deleteRule.call(this, index);
        };
    };
    patchContext(window);
    app.observer.attachContextCallback(patchContext);
    app.attachStopCallback(() => {
        styleSheetIDMap.clear();
        adoptedStyleSheetsOwnings.clear();
    });
    // So far main Document is not triggered with nodeCallbacks
    app.attachStartCallback(() => {
        updateAdoptedStyleSheets(document);
    });
    app.nodes.attachNodeCallback((node) => {
        if (hasAdoptedSS(node)) {
            updateAdoptedStyleSheets(node);
        }
    });
}
exports.default = default_1;
